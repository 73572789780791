.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .loading-animation {
    display: flex;
  }
  
  .loading-animation span {
    animation: dot-animation 1.5s infinite;
  }
  
  @keyframes dot-animation {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
  .loading-animation span:nth-child(2) {
    animation-delay: 0.5s;
  }
  
  .loading-animation span:nth-child(3) {
    animation-delay: 1s;
  }
  